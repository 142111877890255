.divHeader {
	background-color: darkgray;
	height: 35px;
	padding: 0.5rem 0.5rem !important;
}

.tableRow > td {
	vertical-align: middle !important;
	padding: 0.4rem !important;
}

.divTitle {
	font-size: 14pt !important;
	font-weight: bold !important;
	line-height: 1.2 !important;
}

@media (max-width: 768px) {
	.scrollContainer {
		overflow: scroll !important;
	}

	.divContainer {
		box-sizing: border-box;
		min-width: 900px;
	}
}

.divTotalTitle {
	font-weight: bold;
	font-size: 11pt;
}
