.inputDropdown {
	width: 100% !important;
	font-size: 10pt !important;
	border-color: #a9a9a9;
	border-radius: 4%;
}

.inputLabel {
	font-size: 10pt;
	line-height: 2.5;
}

.inputDisabled {
	background-color: #fff;
	border: 0px;
	padding-top: 4px;
}

.valueReadOnly {
	font-size: 10pt !important;
	padding-top: 5px;
}
