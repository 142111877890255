@import '../../styles/filters.scss';

@media (max-width: 991px) {
	.tableHeader {
		font-size: 9pt;
	}

	.scrollContainer {
		overflow: 'scroll';
	}

	.divContainer {
		box-sizing: border-box;
		min-width: 900px;
	}
}

.inputFilter {
	@include globalFilter;
}

.tableRow {
	font-size: 10pt;
}

.collectionsTable {
	overflow-x: initial;
}
