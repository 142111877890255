.Title {
	font-weight: bold;
	font-size: 14pt;
	vertical-align: middle;
	display: inline;
}

.inputFilter {
	font-size: 10pt !important;
	height: 30px !important;
	width: 90% !important;
	margin: 0px auto !important;
}

.tableRow {
	font-size: 10pt;
}

.tableHeader {
	font-size: 12pt;
}

.cantBadge {
	background-color: #e8e82bbd !important;
	color: #000 !important;
	border: 1px solid #000;
	font-size: 9pt !important;
	padding: 0.3rem !important;
	margin-top: 0.8rem !important;
}
