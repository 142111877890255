.styleTextContainer {
	background: transparent;
	border-color: transparent;
	text-align: center;
	height: 150px;
	color: #000;
	width: 100%;
	outline: none;
	font-weight: bold;
	box-shadow: 0px 0px;
	padding-top: 25%;
}

.styleDiv {
	--backGroundColor: rgba(27, 187, 233, 1);
	margin: auto;
	background: var(--backGroundColor);
	width: 170px;
	box-shadow: 2px 2px #ededed;
	cursor: pointer;
}
