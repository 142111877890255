.formButton {
	font-size: 16px;
}

.linkClass {
	color: #fff;
	text-decoration: none;
}

.linkClass:hover {
	color: #fff;
}

.buttonLight {
	color: #007bff !important;
}

.btn:focus {
	border: 0px;
	box-shadow: 0px;
}
