.inputTextArea {
	width: 100% !important;
	padding: 0.25rem 1.75rem 0 0.75rem;
	font-size: 10pt;
}

.label {
	font-size: 10pt;
	padding-top: 6px;
}

.inputDisabled {
	background-color: #fff;
	border: 0px;
}
