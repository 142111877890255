@import '../../styles/filters.scss';

.inputFilter {
	@include globalFilter;
}

@media (max-width: 768px) {
	.scrollContainer {
		overflow: scroll !important;
	}

	.divContainer {
		box-sizing: border-box;
		min-width: 1200px;
	}
}
