.containerCard {
	padding-left: 4rem !important;
	border-radius: 40px !important;
}

@media (max-width: 768px) {
	.containerTable {
		min-width: 900px;
	}

	.containerCard {
		overflow: scroll;
	}

	.affectedTable {
		min-width: 1200px;
	}
}

.tableRow > td {
	vertical-align: middle !important;
	padding: 0.4rem !important;
}

.title {
	text-align: left;
	font-weight: bold;
	font-size: 14pt;
}
