.containerDisplay {
	font-size: 10pt;
}

.contentDisplay {
	--primaryColor: #f4f4f4;
	--secondaryColor: #ccc;
	border: 1px solid var(--secondaryColor);
	background-color: var(--primaryColor);
	padding-top: 0.75rem;
}

.containerBarcode {
	--primaryColor: #fff;
	--secondaryColor: #ccc;
	border: 1px solid var(--secondaryColor);
	background-color: var(--primaryColor);
	padding-top: 0.75rem;
}
