.containerLogin {
	margin: 0px auto;
}

.imgLogo {
	height: 150px;
	padding-left: 15px;
	margin-bottom: 35px;
	margin-top: 25px;
}
