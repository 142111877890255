.inputLabel {
	font-size: 10pt;
	padding-top: 6px;
	line-height: 1.8;
}

.inputLabelCheckBox {
	font-size: 10pt;
	line-height: 1.8;
}

@media (max-width: 768px) {
}

.inputText {
	width: 100% !important;
	height: 35px !important;
	padding: 0 0.375rem 0 0.75rem;
	font-size: 10pt;
	border: 1px solid #ced4da;
	/*border-radius: 4%;*/
}

.inputDisabled {
	background-color: #fff;
	border: 0px;
}

.lock {
	padding-left: 0px !important;
	padding-top: 6px;
	text-align: left;
}

.react-datepicker__close-icon::after {
	padding: 1px;
	font-size: 16px;
	line-height: 1;
}

.divDataPicker > div {
	width: 100%;
}

.loading_icon {
	width: 38px;
	text-align: center;
}
