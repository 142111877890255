.Title {
	font-weight: bold;
	font-size: 11pt;
	margin-bottom: 25px;
}

.inputFilter {
	font-size: 10pt !important;
	width: 90% !important;
	margin: 0px auto !important;
	font-weight: bold !important;
	color: #000 !important;
	font-style: normal !important;
}

.inputFilter:-moz-placeholder {
	font-size: 11pt;
}

.tableRow {
	font-size: 10pt;
}

.tableHeader {
	font-size: 11pt;
}

.voucherTextButton {
	font-size: 11pt;
}
