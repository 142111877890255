@import '../../styles/filters.scss';

.inputFilter {
	@include globalFilter;
}

.containerTable {
	width: 99%;
	padding-left: 0.5%;
	margin-left: 1px !important;
}
