@import '../../styles/filters.scss';

.containerBorder {
	--primaryColor: #fff;
	--secondaryColor: #ccc;
	border: 1px solid var(--secondaryColor);
	background-color: var(--primaryColor);
	padding-top: 0.18rem;
	margin-top: -10px;
	margin-bottom: 1%;
	margin-left: 1%;
	margin-right: 1%;
	flex: unset;
}

.containerBarcode {
	--backgroundColor: #f4f4f4;
	background-color: var(--backgroundColor);
}

.divLabel {
	font-weight: bold;
	font-size: 12pt;
}

.inputFilter {
	@include globalFilter;
}

.tableRow > td {
	vertical-align: middle !important;
	padding: 0.4rem !important;
}

.redLabel {
	color: red;
}
