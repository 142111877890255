.tableRow {
	font-size: 10pt;
	height: 10px;
}

.tableRow > td {
	vertical-align: middle !important;
	padding: 0.4rem !important;
}

.tableHeader {
	font-size: 12pt;
}

@media (max-width: 768px) {
	.tableHeader {
		font-size: 9pt;
	}

	.scrollContainer {
		overflow: scroll !important;
	}

	.divContainer {
		box-sizing: border-box;
		min-width: 1200px;
	}
}

.inputFilter {
	font-size: 10pt !important;
	width: 90% !important;
	margin: 0px auto !important;
	font-weight: bold !important;
	color: #000 !important;
	font-style: normal !important;
}

.containerTable {
	width: 100%;
	/* padding-left: 0.5%;
	margin-left: 1px !important; */
}
