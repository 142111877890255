@media (min-width: 1200px) {
	.size_modal {
		max-width: 90%;
		width: 90%;
	}

	.size_modal > div > div > * {
		max-width: 98% !important;
	}
}
