.title {
	font-family: 'Courier New', Courier, monospace;
}

.semaforo {
	height: 1em;
	width: 1em;
	border-radius: 50%;
	display: inline-block;
	text-align: center;
}

.red {
	background-color: #b11212;
}
.yellow {
	background-color: yellow;
}
.green {
	background-color: green;
}

.grey {
	background-color: gray;
}
