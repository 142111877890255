.step {
	border-radius: 100% !important;
	height: 20px;
	width: 20px;
	line-height: 1.4 !important;
}

.containerBody {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 14px;
}

.title {
	font-size: 12px;
	margin-top: 5px;
}

.formButton {
	height: 37px;
	border-radius: 10px !important;
	font-size: 16px !important;
}
