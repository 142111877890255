.globalTableHeader > th {
	padding: 0.75rem 0px !important;
}

.globalTableRow {
	font-size: 10pt;
	height: 10px;
}

.globalTableRow > td {
	vertical-align: middle !important;
	padding: 0.4rem !important;
}

.globalTableHeader {
	font-size: 10pt;
}

.mainTable table {
	table-layout: auto;
}

@media (max-width: 768px) {
	.globalTableHeader {
		font-size: 9pt;
	}

	/*.scrollContainer {
		overflow: scroll !important;
	}

	.divContainer {
		box-sizing: border-box;
		min-width: 1200px;
	}*/
}
