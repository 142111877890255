body {
	margin: 0;
	padding: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.table thead th {
	vertical-align: middle !important;
}

.btn:focus {
	outline: none;
	box-shadow: none;
}

#nprogress .bar {
	background: rgb(224, 9, 9) !important;
	height: 4px;
}

#nprogress .peg {
	box-shadow: 0 0 10px rgb(224, 9, 9), 0 0 5px #fff;
}

#nprogress .spinner-icon {
	border-top-color: rgb(224, 9, 9);
	border-left-color: rgb(224, 9, 9);
}
