.NavBar {
	background-color: #1bbbe9;
	color: #000;
	padding: 0 !important;
}

.imgLogo {
	height: 30px;
}

.iconButton_ligth {
	color: #fff !important;
	font-size: 11pt !important;
	text-decoration: none !important;
}

.iconButton_dark {
	color: #000 !important;
	font-size: 11pt !important;
	text-decoration: none !important;
}

.LogoMenu {
	padding: 0.5rem;
	background-color: #000;
}
