.divContent {
	border: 1px solid #827b7b;
	margin-top: 20px;
	margin-bottom: 20px;
	margin-left: 2px !important;
	padding-top: 0.7rem;
	padding-bottom: 0.7rem;
}

.labelTitle {
	font-weight: bold;
}
