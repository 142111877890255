@import '../../styles/filters.scss';

.inputFilter {
	@include globalFilter;
}

.buttonAddToCart {
	color: #007bff !important;
	font-size: 12pt !important;
	&:focus {
		box-shadow: 0 0 0 0.2rem #d8d9db80 !important;
	}
}

.tableRow {
	font-size: 10pt;
}

.tableRow > td > div {
	padding-left: 5px;
	padding-right: 5px;
}

.tableHeader > th {
	padding: 0.75rem 0px !important;
}
