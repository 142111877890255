.sidebarWrapper {
	min-height: 100vh;
	margin-left: -3rem;
	-webkit-transition: margin 0.25s ease-out;
	-moz-transition: margin 0.25s ease-out;
	-o-transition: margin 0.25s ease-out;
	transition: margin 0.25s ease-out;
}

.sidebarHeading {
	padding: 0.875rem 1.25rem;
	font-size: 1.2rem;
}

.listGroup {
	width: 3rem;
	height: 100%;
}

.pageContentWrapper {
	min-width: 90vw;
	line-height: 1 !important;
}

.toggled {
	margin-left: 0;
}

.buttonMenu {
	padding: 2px 10px !important;
	font-size: 9pt !important;
}
