@import '../../styles/filters.scss';

.inputFilter {
	@include globalFilter;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}

.classTable {
	width: auto;
}
