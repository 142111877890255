.containerDisplay {
	font-size: 10pt;
}

.contentDisplay {
	--primaryColor: rgba(111, 105, 105, 0.11);
	border: 1px solid var(--primaryColor);
	background-color: var(--primaryColor);
	padding-top: 0.75rem;
}
