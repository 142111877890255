@import '../../styles/filters.scss';

.inputFilter {
	@include globalFilter;
}

@media (max-width: 768px) {
	.scrollContainer {
		overflow: scroll !important;
	}

	.divContainer {
		box-sizing: border-box;
		min-width: 900px;
	}
}

.containerTable {
	width: 99%;
	padding-left: 0.5%;
	margin-left: 1px !important;
}
