.inputDropdown {
	width: 100% !important;
	height: 30px !important;
	font-size: 16px !important;
	border-color: #a9a9a9;
}

.inputLabel {
	font-size: 16px;
}
