.inputText {
	height: 30px;
	padding: 0.375rem 1.75rem 0.375rem 0.75rem;
	font-size: 11pt;
}

.inputDisabled {
	background-color: #e9ecef;
}
​ .inputLabel {
	font-size: 11pt;
}
